import * as React from "react"
import { Link } from "gatsby"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"

const ThesisProjectNavigation = () => {
  const { site } = useSiteMetadata()
  const thesisLinks = site.siteMetadata.mainNavLinks.find(
    l => l.name === "Thesis Project"
  ).sub

  return (
    <div className="row">
      <div className="col">
        <div className="post-header">
          <span>Thesis Project : </span>
          {thesisLinks.map(link => (
            <Link
              key={link.name}
              to={link.slug}
              activeClassName={"text-warning"}
            >
              {link.name}
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ThesisProjectNavigation
