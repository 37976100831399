import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentWrapper from "../../components/Shared/ContentWrapper"
import ContentSection from "../../components/Shared/ContentSection"
import BlogNav from "../../components/Blog/BlogNav"
import BlogPostMedia from "../../components/Blog/BlogPostMediaPreview"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import ThesisProjectNavigation from "../../components/ThesisProject/ThesisProjectNavigation"
import PostAuthorSection from "../../components/Shared/PostAuthorSection"

const OcadUndergradThesisPage2 = ({ data, uri }) => {
  const postData = {
    id: "ocadutp01",
    postType: "Default",
    title: "Orrery Design: The interface",
    slug: "ocad-undergrad-thesis/interface",
    createdAt: "Jan 10, 2020",
    categories: [
      {
        name: "Coding",
        slug: "coding",
        id: "3YN1fgeDhbqNHSGzcUZuUE",
      },
      {
        name: "Web",
        slug: "web",
        id: "6rlTGOLIAdgMKRsTS5zPaP",
      },
      {
        name: "Mobile",
        slug: "mobile",
        id: "3GUFF463ocF1ZISKLUu2l",
      },
    ],
    featuredImage: {
      file: {
        url: "https://images.ctfassets.net/z5m1dcunobst/YBJQZKR0UMXmLWyuILuoz/181dc25335d4d876309a3db2660bdd17/Orreretically_Speaking_4_16_2020_5_46_24_AM.png",
      },
    },
  }

  return (
    <Layout
      title={postData.title}
      scrollImage={postData.featuredImage.file.url}
    >
      <SEO title={postData.title} />

      <ContentWrapper
        nav={
          <BlogNav
            backButton={
              <Link to="/blog" className="ajax back-to-home-btn">
                <span>Back to Blog</span>
              </Link>
            }
          />
        }
      >
        <ContentSection
          id="sec1"
          // description={`by Jerez Bain on ${postData.createdAt}`}
          decoratorPosition="top"
          separator
          sectionClasses="hidden-section bot-element text-left"
        >
          <div className="section-title fl-wrap post-title">
            <h3>{postData.title}</h3>
            <div className="post-header">
              <a href="#">{postData.createdAt}</a>
              <span>Category : </span>
              {postData.categories.map(category => (
                <Link
                  key={category.id}
                  to={`/blog/categories/${category.slug}`}
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </div>

          <BlogPostMedia post={postData} />

          <ThesisProjectNavigation />

          <div className="post-block fl-wrap">
            <div className="post-opt fl-wrap">
              <ul>
                <li>
                  <a href="#">
                    <i className="fal fa-user" /> Jerez Bain
                  </a>
                </li>
                {/*<li>
                  <a href="#">
                    <i className="fal fa-comments-alt" />{" "}
                    <CommentCount config={disqusConfig} placeholder={"..."} />
                  </a>
                </li>*/}
                {/*<li>
                  <span>
                    <i className="fal fa-eye" /> 123 view
                  </span>
                </li>*/}
              </ul>
            </div>
            <div>
              <div className="row pb-5">
                <div className="col">
                  <p>
                    The interface of this prototype has gone through several
                    revisions. Even though this is not the most important part
                    of the installation it certainly demands much attention
                    because it is the means of user interaction with{" "}
                    <Link to="/ocad-undergrad-thesis/orrery">the Orrery</Link>
                    .&nbsp;
                  </p>
                </div>
              </div>

              <div className="row pb-2">
                <div className="col">
                  <h2>Design Inspiration</h2>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col">
                  <img
                    src="https://images.ctfassets.net/z5m1dcunobst/mFEFWYiFZyEgVS56ysNOl/6c003469bd69b507554561cf4e594c93/Screen_Shot_2020-03-13_at_11_01_25_AM.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col">
                  <img
                    src="https://images.ctfassets.net/z5m1dcunobst/1eAfN4LC1B6kSe6AkfWbF3/320d9464caccad25f4340fd79f5f978b/explore_universe_website_animation_tubik.gif"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col">
                  <img
                    src="https://images.ctfassets.net/z5m1dcunobst/74ghxG4YBjlzHITJXW8kb8/b5978b54184abd7968693e0b3407adfd/interface-inspiration-3-rounded.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="row pb-4">
                <div className="col">
                  <h2 className="xl-headline">Companion App</h2>

                  <p>
                    The inspiration for the user interface started with the a
                    focus on touch interaction, touch-gestures and animation.
                    The first thing I needed to do was to outline a user story.
                    In product design and web development, the user story
                    captures a desire the user has and outlines the steps that
                    will be token to meet that desire. Simple enough right? So
                    here's what I came up with.
                  </p>

                  <h3>User Story</h3>

                  <p>
                    As a user wanting to interact with the installation I want
                    to:
                  </p>

                  <ul className="list-group">
                    <li className="list-group-item">
                      Select a date in the past/future and see the planets
                      adjust to their respective positions.
                    </li>
                    <li className="list-group-item">
                      Track the planets’ realtime heliocentric orbits on the
                      screen.
                    </li>
                    <li className="list-group-item">
                      Touch a planet indicator on screen and see realtime
                      indication effect from installation planet.
                    </li>
                    <li className="list-group-item">
                      See social links/QR codes/hashtags to allow me to learn
                      more about the project and the artist.
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="video-wrapper">
                    <div className="youtube_cont">
                      <iframe
                        width="100%"
                        height="250"
                        src="https://www.youtube.com/embed/4LL4dQ140z8?rel=0&amp;wmode=opaque&amp;hd=1&amp;enablejsapi=1"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <h2>Version 1 - Mobile App</h2>
                  <p>
                    This version would focus on portable touch devices that also
                    could support addition features such as AR and close ranged
                    location services. More and more mobile devices are
                    increasingly meeting this expectation so I endeavoured to
                    build a react native mobile app. The main advantages were
                    fast development and implementation of design elements and
                    functionality.
                  </p>
                  <p>
                    I was also using multiple Particle.io Photons, so I could
                    configure, develop, troubleshoot and present using a single
                    device. As my project developed though, so did my needs...
                  </p>
                </div>
              </div>
              <hr />

              <div className="row">
                <div className="col">
                  <h2>Version 2 - Web App / Unity App (UI Only)</h2>
                  <p>
                    After considering the shortcoming of building a mobile app
                    with all of these intended features, it became clear that my
                    approach was limiting what I could build. To get the base
                    functionality working until i could transition into a unity
                    mobile app that would facilitate better animation and Mixed
                    Reality features, i build a web app to communicate with the
                    installation until then.
                  </p>
                </div>
                <div className="col">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/3DRGJWLXg3sAqRPQymVrk4/f558058820c7c05fc72e8aade89fb701/Screen_Shot_2020-04-16_at_8_22_25_PM_min.png"
                  />
                </div>
              </div>
              <hr />

              <div className="row pb-4">
                <div className="col">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/7jOC30btFRU7bhbjjX6Jfm/d48c9decbf3e089bddad3bb421aa8e46/Orreretically_Speaking_4_16_2020_5_41_55_AM.png"
                  />
                </div>
                <div className="col">
                  <h2>Version 3 - Unity App</h2>
                  <p>
                    The unprecedented times of 2020 affected my chance of
                    finishing the physical Orrery. So I focused on building out
                    the complete virtual installation in this iteration.
                  </p>
                  <p>
                    One of the great benefits of using Unity is the ability to
                    build interactive experiences that can be considered for
                    real life prototyping and implementation. I must admit for
                    the longest time that the aesthetics, physics, and
                    functionality of my thesis project since conception has ,
                    for the most part, existed within my imagination. There was
                    no point in time up until creating this, that I was able to
                    get the majority of what i envisioned this project of
                    becoming in one location. I can accept that this may have
                    been to my detriment, but it has been very satisfactory to
                    see my initial thoughts take form and motion without initial
                    real world restraints such as funding for prototyping
                    materials.
                  </p>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/7tVqyAzACVXkYhYoyvunjr/39cbd6839cdf18e84367c8467fc52f55/UI_Map.png"
                    alt="UI Map"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <h2>UI Elements</h2>
                  <p>
                    The date display and the speed controls text&nbsp;are the
                    only elements consistently visible regardless of the state
                    of the menu.
                  </p>
                  <p>
                    The date display is formatted to show [Month | Day | Year],
                    and in perfect sync with the planet's positions.
                  </p>
                  <p>
                    The speed controls allow you to control how many days the
                    orrery advances per second.
                  </p>
                  <p>
                    The graphics quality settings toggles the state of Realtime
                    Lighting emitted from the sun. Realtime lighting can be
                    really costly on mid-range and lower graphics cards, so
                    including this as an option would give some flexibility to
                    enjoying the full experience focusing on performance or
                    visual effects.
                  </p>
                  <p>
                    The date picker is one of the most important parts of the
                    UI. Placed directly above the date display and almost
                    central to the screen draws the user's immediate attention.
                  </p>
                  <p>
                    The Historic Events panel is populated by Wikipedia's API
                    showing a three random historical events that&nbsp;
                    happened&nbsp;on the last date&nbsp;selected by the date
                    picker - the year is all that is different.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <PostAuthorSection />
        </ContentSection>
      </ContentWrapper>
    </Layout>
  )
}

export default OcadUndergradThesisPage2
