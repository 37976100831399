import * as React from "react"
import { useSiteMetadata } from "../../hooks/useSiteMetadata"
import { getSrc } from "gatsby-plugin-image"

const PostAuthorSection = () => {
  const { site, aboutImage } = useSiteMetadata()
  const authorImageSrc = getSrc(aboutImage)

  return (
    <div className="post-author">
      <div className="author-img text-center">
        {/*<Img fixed={data.aboutImage.childImageSharp.fixed} />*/}
        <img alt="" src={authorImageSrc} />
      </div>
      <div className="author-content">
        <h5>
          <a href="#">Jerez Bain</a>
        </h5>
        <p>
          I bring your ideas and designs to life. From small business websites
          to interactive web applications. My passion is building things and I
          put my best in every line of code, measurement, and decision.
        </p>
        <div className="author-social text-center">
          <ul>
            {site.siteMetadata.social.map((item, index) => (
              <li key={index}>
                <a
                  href={item.url}
                  target="_blank"
                  title={item.name}
                  rel="noreferrer"
                >
                  <i className={`fab fa-${item.icon}`} />
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PostAuthorSection
